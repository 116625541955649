import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDepartments(ctx, data) {
      return useJwt.getDepartments(data).then(response => response);
    },
    createDepartment(ctx, data) {
      return useJwt.createDepartment(data).then(response => response);
    },
    updateDepartment(ctx, data) {
      return useJwt.updateDepartment(data).then(response => response);
    },
    deleteDepartment(ctx, id) {
      return useJwt.deleteDepartment(id).then(response => response);
    },
  },
};
